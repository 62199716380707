@charset "UTF-8";

// Pagetop

.pagetop {
  display: none;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
	
	img {
		height: 100%;
	}

  @include media(large) {
    bottom: 6rem;
    right: 3rem;
  }

}
.pagetop a {
  display: block;
  background-color: rgba($brand-color,0.8);
  text-align: center;
  color: #fff;
  //line-height: 60px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  width: 44px;
  height: 44px;
  padding: 0.4rem;

  @include media(large) {
    width: 60px;
    height: 60px;
    padding: 0.6rem;
  }
}
.pagetop a:hover {
  background-color: rgba(darken($brand-color,10%),0.8);
  color: #fff; }
