@charset "UTF-8";

// ---------- ---------- ---------- footer

footer {
  background: $brand-color;
  padding: 1.6rem 0;
  width: 100%;
  min-width: 320px;
  & small {
    color: #fff;
    display: block;
    text-align: center;
    //letter-spacing: .2em;
    }
  @include media(large) {
   min-width: $width-large;
    //padding-left: $width-menu;
  }
  @include media(xlarge) {
    min-width: 100%;
    padding-left: $width-menu;
  }
}

.address {
  color: #fff;
  list-style: none;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  & .hp {
    font-size: 140%;
    font-weight: bold;
  }
}
