@charset "UTF-8";

/* Font Set
---------- ---------- ---------- ---------- ---------- */

p {
  font-size: inherit;
  line-height: inherit;
  //margin-top: 0.5em;
  margin-bottom: 1em;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

.small {
  font-size: 62.5%;
  font-weight:normal;
  &.small-block {
    display: inline;
    @include media(large) {
      display: block;
      margin-top: 1em;
    }
  }
}
.middle {
  font-size: 75%;
  font-weight: normal;
}
.tiny {
  font-size: 50%;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: 0.8rem;
  line-height: 1.4;
}

h1 {
  font-size: 3rem;
  @include media(large) {
    font-size: 4.8rem;
  }
}
// ---------- ---------- ---------- ---------- ---------- 事業名タイトル
h1.title-division {
  //background: $brand-color;
  border-top: 1px dotted #cdcdcd;
  color: #555;
  //font-family: "heisei-maru-gothic-std";
  //font-size: 3rem;
  font-weight: 800;
  line-height: 1.2;
  margin-top: 1rem;
  margin-bottom: 3rem;
  padding-top: 1.2rem;
  & span {
    display: block;
  }

  @include media(large) {
    margin-bottom: 5rem;
    & span {
      display: inline;
    }
  }
}

h2 {
  font-size: 2.4rem;
  @include media(large) {
    font-size: 4.0rem;
  }
}
// ---------- ---------- ---------- ---------- ---------- キャッチコピー
.catch-copy {
  color: $brand-second;
  font-size: 1.6rem;
  & span {
    display: block;
  }

  @include media(large) {
    & span {
      display: inline;
    }
  }
}

h3 {
  font-size: 2.0rem;
  @include media(large) {
    font-size: 3.0rem;
  }
  &.read-copy {
    display: inline-block;
    &::before {
      content: '';
      background: url(/common/img/icon/icon-copy.svg);
      background-size:contain;
      width: 1.4em;
      height: 1.4em;
      zoom:1;
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.4em;
      position: relative;
      top: -0.275rem;
    }
    &.brand {
      color: $brand-color;
    }
    &.second {
      color: $brand-second;
    }
    &.third {
      color: $brand-third;
    }
    &.fourth {
      color: $brand-fourth;
    }
    &.five {
      color: $brand-five;
    }
  }
}

h4 {
  font-size: 1.8rem;
  @include media(large) {
    font-size: 2.6rem;
  }
}

h5 {
  font-size: 1.6rem;
  @include media(large) {
    font-size: 2.0rem;
  }
  &.style-01 {
    color: $brand-third;
    border-bottom: 3px solid $brand-third;
    text-align: center;
  }
}

h6 {
  font-size: 1.4rem;
  @include media(large) {
    font-size: 1.6rem;
  }
}

// border 付 head
.head-border {
  border-bottom: 2px solid $default-color;
  font-size: 1.8rem;
  line-height: 1.4;
  margin-bottom: 0.9rem;

  @include media(large) {
    font-size: 2.2rem;
  }
  
  &.middle {
    border-bottom: 1px solid $default-color;
    font-size: 1.35rem;
    @include media(large) {
      font-size: 1.65rem;
    }
  }

  &.brand {
    border-color: $brand-color;
    color: $brand-color;
  }
  &.second {
    border-color: $brand-second;
    color: $brand-second;
  }
  &.third {
    border-color: $brand-third;
    color: $brand-third;
  }
  &.fourth {
    border-color: $brand-fourth;
    color: $brand-fourth;
  }
  &.five {
    border-color: $brand-five;
    color: $brand-five;
  }
}

// block head
.head-block {
  background-color: $default-color;
  color: #fff;
  text-align: center;
  margin: 0 0 0.9rem;
  padding: 0.6rem 0 0.25rem;

  &.round {
    border-radius: 2rem;
  }

  &.brand {
    background-color: $brand-color;
  }
  &.second {
    background-color: $brand-second;
  }
  &.third {
    background-color: $brand-third;
  }
  &.fourth {
    background-color: $brand-fourth;
  }
  &.five {
    background-color: $brand-five;
  }
}

/* テキストの配置 */
.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }


/* ========================================================================
   Component: Column
 ========================================================================== */
[class*='column-'] {
  -webkit-column-gap: 25px;
  -moz-column-gap: 25px;
  column-gap: 25px;
}
/* Width modifiers
 ========================================================================== */
.column-1-2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.column-1-3 {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}
.column-1-4 {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
}
.column-1-5 {
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-count: 5;
}
.column-1-6 {
  -webkit-column-count: 6;
  -moz-column-count: 6;
  column-count: 6;
}

/* Tablet and bigger */
@include media(large) {
  .column-medium-1-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .column-medium-1-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .column-medium-1-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .column-medium-1-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
  .column-medium-1-6 {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
  }
}

/* ***** code ***** */
pre {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
}
code,
pre {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  color: #484848;
  font-family: monospace;
  font-size: 1.4rem;
  margin: 16px 0; }

pre {
  padding: 8px 1.5em; }

code {
  padding: 2px 3px;
  margin: 0 4px;
  vertical-align: middle; }

pre code {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  vertical-align: inherit; }

