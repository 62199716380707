@charset "UTF-8";
// navigation

/* Sub Nav */
.sub-nav,
.sub-nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.sub-nav li {
  & > a {
	color: $gray-dark;
	display: block;
	text-decoration: none;
	padding: 1.2rem;
    &:hover {
      background: $gray-lightest;
    }
  }
  &.title {
    font-weight: bold;
  }
}

/* List Nav */
.list-nav,
.list-nav ul {
	margin-left: -0.75em;
	padding: 0;
	list-style: none;
	@include make-flex;
}

.list-nav {
  & > * {
  	-webkit-box-flex: 0;
  			-webkit-flex: none;
  					-ms-flex: none;
  							flex: none;
  	padding-left: 0.75em;
  }

  &.white a {
    color: #fff;
  }
}
.list-nav.line > :before {
  content: "";
  display: inline-block;
  height: 1em;
  vertical-align: middle;
	margin-bottom: 4px;
}
.list-nav.line > :nth-child(n+2):before {
  margin-right: 0.75em;
  border-left: 1px solid #dddddd;
}
