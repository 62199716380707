@charset "UTF-8";

@import "drawer/variables";
/* header */
header {
  //background: $brand-color;
  width: 100%;
  position: fixed;
  z-index: 11;
  top: 0;
  @include media(xlarge) {
    //min-width: 100vw;
    padding-left: $drawer-width;
  }

  &.test {
    padding-left: 0;
  }
}

.header-wrap {
  background: $brand-color;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.4);
  z-index: 102;
}

.header {
  @include make-flex;
  justify-content: space-between;
  align-items: center;
  height: $height-header;
  @include media(xlarge) {
            justify-content: flex-end;
  }
}
