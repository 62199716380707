@charset "UTF-8";

@mixin media($media-width) {
	@if $media-width == medium {
		@media only screen and (min-width: $breakpoints-medium) {
			@content;
		}
	}
	@else if $media-width == large {
		@media only screen and (min-width: $breakpoints-large) {
			@content;
		}
	}
	@else if $media-width == xlarge {
		@media only screen and (min-width: $breakpoints-xlarge) {
			@content;
		}
	}
}