@charset "UTF-8";
/* パンくず */

.breadlist {
  font-size: 1.1rem;
  padding: 1rem 1.4rem .9rem;
  margin-bottom: 1.6rem;
  list-style: none;
  background-color: #eceeef;
  border-radius: .4rem;
  @include make-flex;
  // clearfix
  &::after {
    display: table;
    clear: both;
    content: ""; }
  & > li {
    line-height: 1.1;
    color: $link-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > li a:visited {
    color: inherit; }
  & > li + li::before {
    padding-right: .5rem;
    padding-left: .5rem;
    color: #818a91;
    content: "/"; }
  & > .active {
    color: #818a91; }
}
