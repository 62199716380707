@charset "UTF-8";
/* label */

.label {
  background-color: $brand-color;
  border-radius: 2px;
  display: inline-block;
  //margin-right: 1em;
  padding: 0.175em 0.425em 0.275em;
  font-size: 87.5%;
  font-weight: bold;
  //line-height: 1;
  color: #fff;
  text-align: center;
  //white-space: nowrap;
  position: relative;
  //top: -2.0px;

  @include media(large) {
    padding: 0.175em 0.425em 0.25em;
  }

  &.large {
    font-size: 1.4rem;
    @include media(large) {
      font-size: 1.6rem;
    }
  }

  &.brand-second {
    background-color: $brand-second; }
  &.brand-third {
    background-color: $brand-third; }
  &.brand-fourth {
    background-color: $brand-fourth; }
  &.brand-five {
    background-color: $brand-five; }

  &.outline {
    background: #fff;
    border: 1px solid #00a0e9;
    color: #00a0e9;
    padding: 0.2em 0.425em 0.125em;

    &.brand {
      border-color: $brand-color;
      color: $brand-color; }
    &.brand-second {
      border-color: $brand-second;
      color: $brand-second; }
    &.brand-third {
      border-color: $brand-third;
      color: $brand-third; }
    &.brand-fourth {
      border-color: $brand-fourth;
      color: $brand-fourth; }
    &.brand-five {
      border-color: $brand-five;
      color: $brand-five; }
  }

  &.extended {
    display: block;
  }
}

span.label {
  margin-right: 0.8rem;

  @include media(large) {
    margin-right: 1rem;
  }
}
