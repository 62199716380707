@charset "UTF-8";

/* Default
---------- ---------- ---------- ---------- ---------- */

div,dl,dt,dd,ul,ol,li,
h1,h2,h3,h4,h5,h6,
pre,form,p,blockquote,th,td {
  margin: 0;
  padding: 0; }

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

//html, body {
//  min-width: initial;
//  @include media(large) {
    //min-width: $width-xlarge;
//  }
//}

html {
  font-size: 62.5%; // 16px -> 10px
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%; }

body {
  background: #fff url(../img/bg.png) repeat;
  //font-family: Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-family: "YakuHanJPs", Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic,  "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.4rem;
  line-height: 1.6;
  letter-spacing: 0.0425em;
  min-height: 100%;
  //height: 100%;
  text-rendering: optimizeLegibility;
  //font-feature-settings: "palt";
  //-moz-font-feature-settings: "liga", "kern";
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //font-family: -apple-system, sans-serif;

  @include media(large) {
      font-size: 1.6rem;
      line-height: 1.8;
      //min-width: $width-xlarge;
  }
}

// ---------- ---------- ---------- ---------- ---------- アンカーテキスト
a {
	color: $link-color;
  text-decoration: none;
	&:hover {
		color: $link-hover-color;
    text-decoration: underline;
	}
}

a.link-icon span:before {
	content: url(/common/img/link-icon.svg);
	width: 1em;
	height: 1em;
	zoom:1;
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.2em;
	position: relative;
	top: -4px; }
a.link-icon.button span:before {
	top: -2px; }
a.link-icon-right span:after {
	content: url(/common/img/link-icon.svg);
	width: 1em;
	height: 1em;
	zoom:1;
	display: inline-block;
	vertical-align: middle;
	margin-left: 0.2em;
	position: relative;
	top: -4px; }
a.link-icon-right.button span:after {
	top: -1px; }

img {
  /*width: 100%;*/
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle; }

hr {
  max-width: $width-large;
  height: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid $gray-lighter;
  border-left: 0;
  margin: 1.2rem auto;
  clear: both;
  @include media(large) {
    margin: 1.6rem auto; }
}

.border {
  border: 1px solid #999; }

