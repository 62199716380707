@charset "UTF-8";

/* ---------- 横列 */
.grid {
  max-width: $width-large;
  margin: 0 (-$grid-gutter);
  @include make-flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  @include media(large) {
    max-width: $width-large;
		margin: 0 (-$grid-gutter*1.2);
  }
}

 /* ---------- 縦列 */
.grid-file {
  max-width: $width-large;
  margin: 0 (-$grid-gutter);
  @include make-flex;
  -webkit-flex-flow: column wrap;
      -ms-flex-flow: column wrap; /* IE10 */
          flex-flow: column wrap;
  @include media(large) {
    max-width: $width-large;
		margin: 0 (-$grid-gutter*1.2); }
}

.grid .grid {
  margin: (-$grid-gutter);
  @include media(large) {
    margin: 0 (-$grid-gutter*1.2); }
}

.column {
  padding: $column-gutter;
    -webkit-flex: 1 1 0;
	      -ms-flex: 1 1 0;
		        flex: 1;
  @include media(large) {
    padding: $column-gutter*1.2;
    width: auto; }
}

.raw-column {
  text-align: center;
  padding: $column-gutter;
  width: 100%;
  @include media(large) {
    padding: $column-gutter*1.2;
    width: auto; }
}

.column > .column {
	padding: $column-gutter 0;
  @include media(large) {
    padding: $column-gutter*1.2 0; }
}

.grid-file > .column {
			-webkit-flex: 0 1 auto;
					-ms-flex: 0 1 auto;
							flex: 0 1 auto; }
.grid-file > .column:first-child {
	margin-top: (-$grid-gutter); }

/*.grid .column .grid:not(:first-child) {
  margin: 0 -1rem; }*/

@include media(large) {
	.column > .grid {
		margin: (-$grid-gutter*1.2); }

	/*.grid > .column > .grid {
		margin: 0 (-$grid-gutter*1.2); }
	.grid > .column > .grid:first-child {
		margin: (-$grid-gutter*1.2); }*/
}

/* ++++++++++++++++++++++++++++++ Smart Phone 767px以下*/
@for $i from 1 through $grid-columns {
	.small-#{$i} {
		@include make-grid(100%/($grid-columns / $i));
	}
	.small-#{$i}-center {
		@include make-grid(100%/($grid-columns / $i));
		margin: 0 auto;
	}
	.small-offset-#{$i} {
		margin-left:(100%/($grid-columns / $i));
	}
}

@include media(large) {
	@for $i from 1 through $grid-columns {
		.large-#{$i} {
			@include make-grid(100%/($grid-columns / $i));
		}
		.large-#{$i}-center {
			@include make-grid(100%/($grid-columns / $i));
			margin: 0 auto;
		}
		.large-offset-#{$i} {
			margin-left:(100%/($grid-columns / $i));
		}
	}
}

/* ++++++++++++++++++++++++++++++ 12 column */
	@for $i from 1 through $grid-columns {
		.column-#{$i} {
			@include make-grid(100%/($grid-columns / $i));
		}
	}
