@charset "UTF-8";

// Flex Mixin

@mixin make-flex {
	-js-display: flex; /* IE9対応 flexibility.js */
			display: block; /* Android4.3以前標準ブラウザ・iOS6以前Safari スマホで強制1カラム*/
  		display: -webkit-flex; /* safari（PC） */
		  display: -ms-flexbox; /* IE10 */
		  display: flex;
}

@mixin make-flex-inline {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }
  