@charset "UTF-8";
/* Layout */

/* 横幅100%用
---------- ---------- ---------- ---------- ---------- */
.wrapper {
  width: 100%;
  min-width: 320px;
@include media(large) {
  min-width: $width-large; }
}

/* Breakpointごとのメイン横幅
---------- ---------- ---------- ---------- ---------- */
.container {
  padding: 0 1rem;
  margin: 0 auto;
  width: 100%;
  //max-width: 100%;
  @include media(large) {
    padding: 0 1.2rem;
    width: $width-large;
    //min-width: $breakpoints-large; //$width-large;
    }
}

/* Flex Layout Column
---------- ---------- ---------- ---------- ---------- */
/* 大枠のflex*/
.flex-contents {
  @include make-flex;
  flex-flow: column wrap;
  align-content: space-between;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
  padding-top: $height-header;
}



// 2021.04.30 add

$base-spacer: 1em;

// spacer
//
.spacer {
  margin-top: $base-spacer !important;
}

.spacer-large {
  margin-top: $base-spacer * 2 !important;
}

.spacer-small {
  margin-top: $base-spacer / 2 !important;
}
