@charset "UTF-8";

/* Common
---------- ---------- ---------- ---------- ---------- */
.clearfix:after {
    content:" ";
    display:block;
    clear:both; }

.block-center {
	display: block;
	margin: 0 auto; }
  
.ov-hidden {
  overflow: hidden; }

/* Fonts */
.fwN { font-weight: normal !important;}
.fwB { font-weight: bold !important;}
.fzSS  { font-size:  72%;}/* base 14px ->  10px */
.fzS   { font-size:  86%;}/* base 14px ->  12px */
.fzM   { font-size: 100%;}
.fzL   { font-size: 115%;}/* base 14px ->  16px */
.fzLL  { font-size: 129%;}/* base 14px ->  18px */
.fzLLL { font-size: 143%;}/* base 14px ->  20px */
sup   { font-size: 78%; vertical-align: text-top;}
sub   { font-size: 78%; vertical-align: baseline;}

/* Fonts color -------------------------------------------------------------- */
.fc-brand { color: $brand-color; }
.fc-second { color: $brand-second; }
.fc-third { color: $brand-third; }
.fc-fourth { color: $brand-fourth; }
.fc-five { color: $brand-five; }
.fc-red { color: #cc0000;}
.fc-orange { color: #F18F2C;}
.fc-green { color: #63a764;}
.fc-white { color: #fff;}
.fc-black { color: #333;}
.fc-gray-dark { color: $gray-dark; }
.fc-gray { color: $gray; }
.fc-gray-light { color: $gray-light; }
.fc-gray-lighter { color: $gray-lighter; }
.fc-gray-lightest { color: $gray-lightest; }

/* Background color -------------------------------------------------------------- */
.bg-01 { background-color: #DBCACA; }
.bg-02 { background-color: #D3E6CA; }
.bg-03 { background-color: #C3D0DB; }
.bg-04 { background-color: #D0C9B8; }
.bg-05 { background-color: #CDD7BC; }
.bg-brand { background-color: $brand-color; }
.bg-musashi { background-color: $musashi-color; }
.bg-total { background-color: $total-color; }
.bg-kenmin { background-color: $kenmin-color; }
.bg-ifrend { background-color: $ifrend-color; }
.bg-kotobuki { background-color: $kotobuki-color; }

/* 汎用margin & padding -------------------------------------------------------------- */
@for $i from 1 through 25 {
  .mt#{$i * 4} {
    margin-top: #{($i * 4) / 10}rem;
    @include media(large) {
      margin-top: #{($i * 4) / 10 * 1.5}rem; }
  }
  .mb#{$i * 4} {
    margin-bottom: #{($i * 4) / 10}rem ;
    @include media(large) {
      margin-bottom: #{($i * 4) / 10 * 1.5}rem; }
  }
  .mr#{$i * 4} {
    margin-right: #{($i * 4) / 10}rem;
    @include media(large) {
      margin-right: #{($i * 4) / 10 * 1.5}rem; }
  }
  .ml#{$i * 4} {
    margin-left: #{($i * 4) / 10}rem;
    @include media(large) {
      margin-left: #{($i * 4) / 10 * 1.5}rem; }
  }
  .pt#{$i * 4} {
    padding-top: #{($i * 4) / 10}rem;
    @include media(large) {
      padding-top: #{($i * 4) / 10 * 1.5}rem; }
  }
  .pb#{$i * 4} {
    padding-bottom: #{($i * 4) / 10}rem;
    @include media(large) {
      padding-bottom: #{($i * 4) / 10 * 1.5}rem; }
  }
  .pr#{$i * 4} {
    padding-right: #{($i * 4) / 10}rem;
    @include media(large) {
      padding-right: #{($i * 4) / 10 * 1.5}rem; }
  }
  .pl#{$i * 4} {
    padding-left: #{($i * 4) / 10}rem;
    @include media(large) {
      padding-left: #{($i * 4) / 10 * 1.5}rem; }
  }
}

.ma0{
  margin: 0;
}
.mt0{
  margin-top: 0;
}
.mr0{
  margin-right: 0;
}
.mb0{
  margin-bottom: 0;
}
.ml0{
  margin-left: 0;
}

.pa0{
  padding: 0;
}
.pt0{
  padding-top: 0;
}
.pr0{
  padding-right: 0;
}
.pb0{
  padding-bottom: 0;
}
.pl0{
  padding-left: 0;
}

.flex {
 @include make-flex;
}

.vertical-adj {
 @include make-flex;
  -webkit-align-items: center; /* Safari */
          align-items: center;
}