@charset "UTF-8";
/* button */

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  line-height: 1;
  &:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
}

.button {
  background-color: $default-color;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  //text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  line-height: 1;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  vertical-align: middle;
  padding: 1.2rem 1.4rem 1.1rem;
  margin: 0.4rem 0;
  &:hover {
    background-color: lighten($default-color,15%);
    color: #fff;
    text-decoration: none; }
  & a:visited .button {
    color: inherit; }
  // EXPANDED
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  // サイズ
  &.tiny {
    font-size: 1rem;
    padding: 0.75rem; }
  &.small {
    font-size: 1.2rem;
    padding: 0.9rem 0.625rem 0.75rem; }
  &.large {
    font-size: 1.8rem;
    padding: 1.5rem 1.8rem 1.4rem; }
  // カラー
  &.brand {
    background-color: $brand-color;
    &:hover {
    background-color: darken($brand-color,15%); }
  }
  &.brand-second {
    background-color: $brand-second;
    &:hover {
    background-color: darken($brand-second,15%); }
  }
  &.brand-third {
    background-color: $brand-third;
    &:hover {
    background-color: darken($brand-third,15%); }
  }
  &.brand-fourth {
    background-color: $brand-fourth;
    &:hover {
    background-color: darken($brand-fourth,15%); }
  }
  &.brand-five {
    background-color: $brand-five;
    &:hover {
    background-color: darken($brand-five,15%); }
  }
  // アウトライン
  &.outline {
    background-color: #fff;
    border: 1px solid $default-color;
    color: $default-color;
    text-shadow: none;
    &:hover {
      background-color: lighten($default-color,75%); }
      // アウトライン カラー
      &.brand {
        background-color: #fff;
        border: 1px solid $brand-color;
        color: $brand-color;
        &:hover {
          background-color: lighten($brand-color,35%); }
        }
      &.brand-second {
        background-color: #fff;
        border: 1px solid $brand-second;
        color: $brand-second;
        &:hover {
          background-color: lighten($brand-second,35%); }
        }
      &.brand-third {
        background-color: #fff;
        border: 1px solid $brand-third;
        color: $brand-third;
        &:hover {
          background-color: lighten($brand-third,35%); }
        }
      &.brand-fourth {
        background-color: #fff;
        border: 1px solid $brand-fourth;
        color: $brand-fourth;
        &:hover {
          background-color: lighten($brand-fourth,35%); }
        }
      &.brand-five {
        background-color: #fff;
        border: 1px solid $brand-five;
        color: $brand-five;
        &:hover {
          background-color: lighten($brand-five,35%); }
        }
  }
  &.pdf span {
    &:after {
      content: url(/common/img/icon/icon-pdf.svg);
      display: inline-block;
      margin-left: 0.6em;
      padding-top: 0.3em;
      vertical-align: middle;
    }
  }
}

form button.button-block {
  display: block;
  width: 100%;
  @include media(large) {
  form button.button-block {
    margin: 2rem auto;
    width: 60%; }
  }
}
