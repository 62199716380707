/*!------------------------------------*\
    Sidebar
\*!------------------------------------*/
.drawer--sidebar {
  background-color: $drawer-bgColor;
}

.drawer--sidebar .drawer-contents {
  background-color: $drawer-pageContents-bgColor;
}

@include media(xlarge) {
  .drawer--sidebar .drawer-hamburger {
    display: none;
    visibility: hidden;
  }

  .drawer--sidebar .drawer-nav {
    display: block;
    transform: none;
    position: fixed;
    width: $drawer-width;
    height: 100%;
  }

  /*! Left */
  .drawer--sidebar.drawer--left .drawer-nav {
    left: 0;
    border-right: 1px solid $drawer-borderColor;
  }

  .drawer--sidebar.drawer--left .drawer-contents {
    margin-left: $drawer-width;
  }

  /*! container */
//  .drawer--sidebar .drawer-container {
//    max-width: $drawer-container-maxWidth-sm;
//  }
}

