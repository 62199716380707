@charset "UTF-8";

// AMG特価
.tag-amg {
  background-color: $color-yellow;
  border: 1px solid $color-red;
  border-radius: 0.4rem;
  color: $color-red;
  display: inline-block;
  font-weight: bold;
  padding: 0.2rem 0.6rem;
}

// 値引率
.discount {
  background-color: $color-red;
  border: 3px solid darken($color-red, 10%);
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-family: arial;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  //padding: .4rem;
  position: relative;
  width: 6rem;
  height: 6rem;

  @include media(large) {
    width: 6.4rem;
    height: 6.4rem;
  }

  & p {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 1rem;
    left: 0.6rem;
    @include media(large) {
      top: 1.1rem;
      left: 0.5rem;
    }
    & span {
      font-size: 150%;
      &::after {
        content: "\A";
        white-space: pre;
      }
    }
  }
}

// タグのグループ
.tag-group {
  @include make-flex;
  -webkit-align-items: center;
  align-items: center;

  & .price {
    color: $color-red;
    font-size: 150%;
    font-weight: bold;
    &::before {
      content: '';
      margin-left: .8rem;
    }
    &::after {
      content: '';
      margin-right: .8rem;
    }
  }
}
