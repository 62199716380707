@charset "UTF-8";
/* Block */
$block-gutter: .625em;

.block {
	border: 1px solid $default-color;
  color: inherit;
  line-height: 1.4;
  padding: 1rem 1.2rem 1rem;

  &.center {
    text-align: center;
  }

  &.brand {
    border-color: $brand-color;
    color: $brand-color;
  }
  &.second {
    border-color: $brand-second;
    color: $brand-second;
  }
  &.third {
    border-color: $brand-third;
    color: $brand-third;
  }
  &.fourth {
    border-color: $brand-fourth;
    color: $brand-fourth;
  }
  &.five {
    border-color: $brand-five;
    color: $brand-five;
  }
}

.block.height {
  height: 100%; }
.block-body {
  padding: $block-gutter;
  @include media(large) {
    padding: $block-gutter*1.2;
  }
  & .grid { margin: -$grid-gutter; }
}
