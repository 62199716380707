@charset "UTF-8";

// 各ページの電話番号とお問い合わせフォームへのリンク
.contact-box {
  background: #F8FCF7;
  margin-top: 4rem;
  padding: 2rem;
}

.contact-word {
  border-top-width: 1px;
  border-top-style: dotted;
  border-left-width: 1px;
  border-left-style: dotted;
  display: inline-block;
  margin-bottom: 1rem;
  padding: .6rem 0 0 1rem;

  &.tel {
    border-top-color: $brand-second;
    border-left-color: $brand-second;
  }
  &.form {
    border-top-color: $brand-third;
    border-left-color: $brand-third;
  }
}

.contact-button {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  vertical-align: middle;
  padding: 0.8rem 1.4rem 0.4rem;
  margin: 0;
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  &.telephone {
    background: $brand-second;
    color: #fff;

    & span {
      font-size: 1.3em;
      &::before {
        content: '';
        background: url(/common/img/icon/icon-tel.svg);
        background-size:contain;
        width: 1.4em;
        height: 1.4em;
        zoom:1;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.4em;
        position: relative;
        top: -0.275rem;
      }
    }
    &:hover {
      background: darken($brand-second,15%);
    }
  }

  &.form {
    background: $brand-third;
    color: #fff;

    & span {
      font-size: 1em;
      &::before {
        content: '';
        background: url(/common/img/icon/icon-form.svg);
        background-size:contain;
        width: 1.8em;
        height: 1.8em;
        zoom:1;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.4em;
        position: relative;
        top: -0.175rem;
      }
    }
    &:hover {
      background: darken($brand-third,15%);
    }
  }
  &.cart {
    background: $brand-color;
    color: #fff;
    padding: 1em;
    & span {
      font-size: 1em;
      &::before {
        content: '';
        background: url(/common/img/icon/icon-form.svg);
        background-size:contain;
        width: 1.8em;
        height: 1.8em;
        zoom:1;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.4em;
        position: relative;
        top: -0.175rem;
      }
    }
    &:hover {
      background: darken($brand-color, 15%);
    }
  }
  &.cart-pdf {
    background: $brand-fourth;
    color: #fff;
    padding: 1em;
    & span {
      font-size: 1em;
      &::before {
        content: '';
        background: url(/common/img/icon/icon-form.svg);
        background-size:contain;
        width: 1.8em;
        height: 1.8em;
        zoom:1;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.4em;
        position: relative;
        top: -0.175rem;
      }
    }
    &:hover {
      background: darken($brand-fourth, 15%);
    }
  }
}
