@charset "UTF-8";

// menu上部のエリア
.menu-logo {
  display: none;
  @include media(xlarge) {
    display: block;
    width: 100%;
    //height: $width-menu;

    & a {
      display: block;
      padding: 1rem;
    }
  }
}

// ハンバーガーメニュー
#burger,
#burger span {
  display: block;
  transition: all .4s;
}
#burger {
  background: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  display: block;
  width: 50px;
  height: 50px;
  z-index: 106;
  padding: 1rem;

  & span {
    //position: absolute;
    //left: 0;
    width: 100%;
    height: 3px;
    background-color: #555;
    //border-radius: 0px;
    &:nth-of-type(1) {
      top: 10px;
    }
    &:nth-of-type(2) {
      margin: 6px 0;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
    &:nth-of-type(4) {
      background-color: transparent;
      font-size: 1.0rem;
      text-align: center;
    }
  }

  & div {
    font-size: 1rem;
    text-align: center;
    letter-spacing: 0;
  }

  @include media(xlarge) {
    display: none;
  }
}
.drawer-open #burger {
  background: transparent;

  & span {
    &:nth-of-type(1) {
      background: #fff;
      -webkit-transform: translateY(9px) rotate(-45deg);
      transform: translateY(9px) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      background: #fff;
      -webkit-transform: translateY(-9px) rotate(45deg);
      transform: translateY(-9px) rotate(45deg);
    }
    &:nth-of-type(4) {
      color: #fff;
    }
  }

  & div {
    color: #fff;
  }
}
