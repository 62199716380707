/*!------------------------------------*\
    Base
\*!------------------------------------*/
.drawer-nav {
  position: fixed;
  z-index: 1;
  top: $height-header;
  overflow: hidden;
  width: $drawer-width;
  height: 100%;
  min-height: 100vh;
  color: $drawer-color;
  background-color: $drawer-bgColor;

  @include media(xlarge) {
    top: 0;
  }
}

.drawer-brand {
  font-size: $drawer-brand-fontSize;
  font-weight: bold;
  line-height: $drawer-navbar-height;
  display: block;
  padding-right: $drawer-gutter-x;
  padding-left: $drawer-gutter-x;
  text-decoration: none;
  color: $drawer-brand-color;
}

.drawer-menu {
  margin: 0;
  padding: 0 0 $height-header*2.5;
  list-style: none;

  @include media(xlarge) {
    padding: 0;
  }

  & li {
    position: relative;
    &:not(.last) {
      border-bottom: 1px solid #cdcdcd;
    }

    &:not(.menu-logo) a {
       background-color: #fefefe;

      &:after {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        //margin: 0 0.6rem 0.2rem 0;
        border-right: 2px solid $brand-color;
        border-top: 2px solid $brand-color;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2rem;
        right: 1rem;
        @include media(large) {
          top: 2.2rem;
          right: 1.4rem;
        }
      }
    }

    &.title {
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 0.4rem 1rem;
    }

    & a {
      color: #555;
      display: block;
      font-size: 1.4rem;
      padding: 1.6rem 1rem;

      &:hover {
        text-decoration: none;
      }

      @include media(large) {
        font-size: 1.4rem;
        padding: 1.6rem 1.4rem;
      }
    }
  }
}

.drawer-menu-item {
  font-size: $drawer-fontSize;
  display: block;
  padding: $drawer-gutter-x;
  text-decoration: none;
  color: $drawer-link-color;
}

.drawer-menu-item:hover {
  text-decoration: underline;
  color: $drawer-hover-color;
  background-color: $drawer-hover-bgColor;
}

/*! overlay */
.drawer-overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: $drawer-overlay-bgColor;
}

.drawer-open .drawer-overlay {
  display: block;
}
