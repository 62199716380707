@charset "UTF-8";

// Setting

// Breakpoints
//$breakpoints-medium: 48em;
$breakpoints-large: 40.0625em;
$breakpoints-xlarge: 79em;

// Width
//$width-medium: 768px;
$width-large: 984px;
$width-xlarge: 1264px;

// PCビュー最大横幅
//$width-pc: 1264px;

// headerの高さ
$height-header: 7rem;

// drawer用のwidth
$width-menu: 28rem;

// カラム数
$grid-columns: 12 !default;

// margin & padding
$grid-gutter: 1rem !default; //.gridのmargin
$column-gutter: 1rem !default; //.columnのpadding
$container-padding: 1.2rem !default; // 左右のpadding

// レイアウト
$main-width: 75% !default;
$sub-width: 25% !default;

// Color
$gray-dark:     #333333 !default;
$gray:          #55595c !default;
$gray-light:    #818a91 !default;
$gray-lighter:  #eceeef !default;
$gray-lightest: #f7f7f9 !default;

$default-color: #333 !default;
$brand-color:   #e9546b !default;
$brand-second:  #f0ad4e !default;
$brand-third:   #3eb370 !default;
$brand-fourth:  #0068b7 !default;
$brand-five:    #d32f2f !default;

$musashi-color:  #0068b6 !default;
$total-color:    #00a85f !default;
$ifrend-color:   #f39800 !default;
$kenmin-color:   #003670 !default;
$kotobuki-color: #d32f2f !default;

$color-red:    #E6061F !default; // 金赤
$color-yellow: #fff000 !default; // Y100

// Settings for the `<body>` element.
$body-bg:    #fff !default;
$body-color: $gray-dark !default;

// Style anchor elements.
$link-color:                 #0033cc !default;
$link-decoration:            none !default;
$link-hover-color:           darken($link-color, 15%) !default;
$link-hover-decoration:      underline !default;
