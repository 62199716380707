@charset "UTF-8";

// table

table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	padding: 0;
	margin: 0; }

table.default {
  & th,td {
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: block;
    padding: 0.5rem;
  }
  & th {
    background: #f9f9f9;
  }
  &.form-chk {
    margin-bottom: 2rem;
    & th {
      text-align: left;
      @include media(large) {
        text-align: right;
        width: 20%;
      }
    }
  }
  & tr:first-child th {
    border-top: 1px solid #ccc;
  }
  @include media(large) {
    & th,td {
      display: table-cell;
      padding: 0.75rem;
    }
    & tr:first-child th,td {
      border-top: 1px solid #ccc;
    }
  }
}

/*table.table-row {
  & thead {
    display: none;
    @include media(large) {
      display: table-header-group; }
  }
   & th,td {
    padding: 0.5rem;
    @include media(large) {
      border: 1px solid #ccc;
      padding: .75rem; }
   }
  & tr {
    border: 1px solid #ccc;
    display: block;
    margin-bottom: 1rem;
    @include media(large) {
      border: none; }
  }
  & th {
    @include media(large) {
      background: #f9f9f9; }
  }
  & td {
    border: 0;
    border-bottom: 1px dotted #ccc;
    display: block;
    text-align: right;
    &:last-child {
      border-bottom: none; }
    &:before {
      content: attr(data-label);
      float: left;
      font-weight: bold; }
    &:after {
      content:" ";
      display:block;
      clear:both; }
  }
}*/

// PCファースト
table.table-row {
  & th,td {
    border: 1px solid #ccc;
    padding: 0.75rem; }
  & th {
    background: #f9f9f9; }

  @media only screen and (max-width: 767px) {
    & thead {
      display: none;
    }
    & tr {
      border: 1px solid #ccc;
      display: block;
      margin-bottom: 1rem;
    }
    & td {
      border: 0;
      border-bottom: 1px dotted #ccc;
      display: block;
      text-align: right;
      &:last-child {
        border-bottom: none; }
      &:before {
        content: attr(data-label);
        float: left;
        font-weight: bold; }
    }
  }
}

// 県民共済 価格表
$price-border-color: $gray-light;

.table-wrap {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.price-list {
  color: #333;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  border: 1px solid $price-border-color;
  font-size: 0.75em;
  
  & tbody tr.oddrow td {
    background: #FDFF9A;
  }

  & tr {    
    &.foreign:not(th):not(.brand) {
      color: #c92a2a;
    }
    &.bg {
      background: #fbf7f7;
    }
  }
  
  & th, td {
    padding: .8rem;
    line-height: 1.4;
    vertical-align: middle;
    border: 1px solid $price-border-color;
  }
  
  & tbody th {
    width: 20%;
    @include media(large) {
      width: auto;
    }
  }
  
  & th {
    background-color: #fff4e6;
    font-weight: normal;
  }
  
  & td {
    //background-color: #ffffff;
    //color: $gray;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    
    &.brand {
      color: #333;
      font-size: 1em;
      font-weight: normal;
    }
  }
  & hr {
    margin: 0.8rem 0;
  }
}

//
table.dataTable {
  border-collapse: collapse;
}
table.dataTable thead th,
table.dataTable thead td {
  padding: 0.6rem 0;
  border-bottom: none;
}
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 1rem 0;
}

//大感謝祭注文フォーム用
.chooseBox{
  /*background: lighten($brand-second, 30%);*/
  &.select{
    display: table-cell;
    vertical-align: middle;
  }
}

table.complex01 {
  font-size: 0.8em;
  @include media(large) {
    font-size: 1em;
  }
  & th,td {
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 0.25rem;
    height: 5em;
    @include media(large) {
      height: 1em;
    }
  }
  & th {
    background: #f9f9f9;
  }
  &.form-chk {
    margin-bottom: 2rem;
    & th {
      text-align: left;
      @include media(large) {
        text-align: right;
        width: 20%;
      }
    }
  }
  td:first-child{
    max-width:8rem; 
    @include media(large) {
      max-width: 100%;
    }
  }
  & tr:first-child th {
    border-top: 1px solid #ccc;
  }
  @include media(large) {
    & th,td {
      display: table-cell;
      padding: 0.75rem;
    }
    & tr:first-child th,td {
      border-top: 1px solid #ccc;
    }
  }
  .select select {
    background: none;
    border-radius: 4px;
    color: #333;
    font-size: 1em;
    line-height: 1.42857143;
    padding: 0.25em;
    position: relative;
    vertical-align: top;
    cursor: pointer;
    display: block;
    outline: none;
    overflow: hidden;
    white-space: nowrap;
    border: none;
    @include media(large) {
      padding: 6px 36px 6px 12px;
    }
  }
  .select:after {
    display: none;
    @include media(large) {
    border: 1px solid #999;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 7px;
    pointer-events: none;
    position: absolute;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 7px;
    margin-top: -5px;
    right: 16px;
    top: 50%; 
    }
  }
}
