@charset "UTF-8";
/* form */

.form-group {
  margin-bottom: 3.6rem;
  @include media(large) {
    margin-bottom: 4.8rem;
  }
}

.form-child{
  padding: 0.5em 0;
}

label {
  display: block;
  max-width: 100%;
  margin-bottom: 6px;
  font-weight: bold; }

.form-control {
  display: block;
  width: 100%;
  height: 2.5em;
  padding: 6px 12px;
  font-size: 1em;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px lighten($brand-color,30%);
          box-shadow: inset 0 1px 1px lighten($brand-color,30%);
  -webkit-transition: border-color ease-in-out .15s, background-color ease-in-out .15s;
       -o-transition: border-color ease-in-out .15s, background-color ease-in-out .15s;
          transition: border-color ease-in-out .15s, background-color ease-in-out .15s;
}
input.form-control:focus {
  border-color: $brand-color;
  background-color: lighten($brand-color,35%);
  outline: 0;
  //-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px lighten($brand-color,30%);
  //        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px lighten($brand-color,30%);
}
textarea.form-control:focus {
  border-color: $brand-color;
  background-color: lighten($brand-color,35%);
  outline: 0;
  //-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px lighten($brand-color,30%);
  //        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px lighten($brand-color,30%);
}
.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
 // ------------------------------------------------------- ドロップダウンリスト
select {
  width: 100%;
}
select::-moz-focus-inner {
  border: 0;
}
.select {
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.select select {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  height: 2.5em;
  font-size: 1em;
  line-height: 1.42857143;
  padding: 6px 36px 6px 12px;
  position: relative;
  vertical-align: top;
  cursor: pointer;
  display: block;
  outline: none;
  overflow: hidden; }
.select:after {
  border: 1px solid #999;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 7px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 7px;
  margin-top: -5px;
  right: 16px;
  top: 50%; }

textarea.form-control {
  height: auto;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radio label,
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-top: 4px;
  margin-left: -20px;
}
.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}
.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

.contactorder{
input[name="zip1"],
input[name="zip2"],
input[name="zip1_mosikomisha"],
input[name="zip2_mosikomisha"]{
width: 100%;
  @include media(large) {
    width: auto;
  }
}
}

//スピンボタンを消した
//input[type="number"]::-webkit-outer-spin-button,
//input[type="number"]::-webkit-inner-spin-button {
//  margin: 0;
//  -webkit-appearance: none;
//     -moz-appearance:textfield;
//}
