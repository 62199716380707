@charset "UTF-8";

// Mixin Grid

@mixin make-grid($width) {
      -webkit-flex: 0 0 $width;
          -ms-flex: 0 0 $width;
              flex: 0 0 $width;
      width: $width;
	max-width: $width; }


@mixin make-grid-columns($columns: $grid-columns, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    @for $i from 1 through $columns {
      .small-#{$i} {
        @extend %grid-column;
      }
    }
    @include media($breakpoint) {
      // Work around cross-media @extend (https://github.com/sass/sass/issues/1050)
      %grid-column-float-#{$breakpoint} {
        float: left;
      }
      @for $i from 1 through $columns {
        .col-#{$breakpoint}-#{$i} {
          @if not $enable-flex {
            @extend %grid-column-float-#{$breakpoint};
          }
          @include make-col-span($i, $columns);
        }
      }
      @each $modifier in (pull, push, offset) {
        @for $i from 0 through $columns {
          .col-#{$breakpoint}-#{$modifier}-#{$i} {
            @include make-col-modifier($modifier, $i, $columns)
          }
        }
      }
    }
  }
}
