@charset "UTF-8";

// リスト
ul, ol {
  list-style: none;
  //line-height: 1.4;
  margin: 0;
  padding: 0; }

ul.default {
  list-style: disc outside;
  padding-left: 1em;
  & ul {
    list-style: circle outside;
    padding-left: 1em;
  }
}

ol.default {
  list-style: decimal outside;
  padding-left: 1em;
  & ol {
    list-style: decimal outside;
    padding-left: 1em;
  }
  
  & li:not(:last-child) {
    margin-bottom: 0.8em;
  }
}


dl {
  & dt {
    font-weight: bold;
    &:not(:first-child) {
      margin-top: 1em; }
  }
  & dd {
    line-height: 1.4;
  }
}

@include media(large) {
dl.dl-horizon {
  line-height: 2; }
dl.dl-horizon dt {
  width: 10em;
  float: left;
  clear: left;
  margin-top: 0;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
dl.dl-horizon dd {
  margin-left: 11.25em; }
}

@include media(large) {
dl.dl-horizon-center {
  line-height: 2; }
dl.dl-horizon-center dt {
  width: 10em;
  float: left;
  clear: left;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  text-align: center;}
dl.dl-horizon-center dd {
  margin-left: 10.25em; 
  line-height: 2;}
dl.dl-horizon-center>.label+dt,
dl.dl-horizon-center>.label+dd {
  line-height: 2;
  padding: 0.2em 0.425em 0.125em;
  margin-bottom: 0.5em;}
}

// FAQ
.faq {
  & li {
    display: block;
    background: url(/common/img/icon/question.svg) no-repeat 1rem;
    background-size: 2rem 2rem;
    padding: 1em 0.6rem 1em 3em;
    color: #f39800;
    font-weight: bold;

    &:nth-child(2n) {
      background: url(/common/img/icon/answer.svg) no-repeat 1rem;
      background-size: 2rem 2rem;
      color: #005bac;
      border-bottom: 1px dashed $brand-third;
    }
  }
}

// 保養所
ul.room-info {
  @include make-flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap; /* IE10 */
          flex-flow: row wrap;
  & li.open {
    background: url(/resort-house/img/icon-open.png) no-repeat;
    background-size: 2rem 2rem;
    margin-right: 2rem;
    padding-left: 2.4rem;
  }

  & li.close {
    background: url(/resort-house/img/icon-close.png) no-repeat;
    background-size: 2rem 2rem;
    margin-right: 2rem;
    padding-left: 2.4rem;
  }
}
