@charset "UTF-8";

// Exclusive

// ---------- ---------- ---------- ---------- ---------- トップ Information
.information {
  background: #fff;
  border-radius: .8rem;
  padding: 1rem 1.6rem 2rem;
  width: 100%;
  & h3 {
    color: $brand-third;
    border-bottom: 1px solid $gray-lighter;
    text-align: center;
  }

  & ul {
    margin-top: 2em;
    & li {
      margin-bottom: 2em;
      & time {
        display: block;
        border-bottom: 1px dotted #e8e8e8;
        color: $gray-light;
        font-weight: bold;
        margin-bottom: .5em;
        padding-bottom: .5em;
        @include media(large) {
          border: none;
          flex: 0 0 12rem;
          margin: 0;
          padding: 0;
        }
      }
      @include media(large) {
        display: flex;
        border-bottom: 1px dotted #e8e8e8;
        margin-bottom: 1em;
        padding: 0 1em 1em;
      }
    }
  }

    @include media(large) {
      & dl {
        & dt, dd {
          border-bottom: 1px dotted #e8e8e8;
          padding: 1em 0;
        }
        & dt {
          width: 10em;
          float: left;
          clear: left;
          padding-left: 1em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:not(:first-child) {
            margin-top: 0;
          }
        }
        & dd {
          margin-top: 0;
          margin-left: 10em;
          padding-right: 1em;
        }
      }
    }
}


// ---------- ---------- ---------- ---------- ---------- ニュースリリース
.news {
  border-top: 1px solid $gray-lighter;
  border-bottom: 1px solid $gray-lighter;
  padding: 2rem 0;

  & .news-body {
    padding: 0 1rem;
    @include media(large) {
      padding: 0 3rem;
    }
  }

  & dl {
    & dt {
      color: $gray-light;
    }

    & dd {
      font-size: inherit;
      line-height: inherit;
      margin-bottom: 1em;
      text-rendering: optimizeLegibility;

      &.news-header {
        color: #0288D1;
        font-family: "YakuHanJPs", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
        font-size: 150%;
        font-weight: 800;
        line-height: 1.4;
        &.head-child {
          border-top: 1px dashed $gray-light;
          margin-top: 2em;
          padding-top: 0.5em;
        }
      }

      & h3.sub-title {
        border-left: 3px solid $brand-color;
        color: $brand-second;
        font-size: 1.4em;
        padding-left: 0.5em;
      }
    }
  }

  & .button {
    line-height: inherit;
  }
}

// ---------- ---------- ---------- ---------- ---------- 下層ページの外枠
.sub-box {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  margin: 1rem 0;
  padding: 1.4rem;
  position: relative;
  min-height: 280px;

  & h4 {
    color: $brand-color;
    font-family: "heisei-maru-gothic-std";
    font-weight: 800;
    line-height: 1.2;
    margin: 2em 0;
  }

  & .logo-guide {
      position: absolute;
      top: -5.4rem;
      right: 0;
      z-index: 106;

      @include media(large) {
        top: -6.8rem;
      }
  }

  @include media(large) {
    margin: 2rem 0;
    padding: 2rem;
  }
}

/* ---------- ---------- ---------- ---------- ---------- カレンダー用の枠 */
$calendar-height: 420px;
.calWrap {
  width: 100%;
  height: $calendar-height;
  max-width: 960px;
  //min-width: 280px;
  margin: 0 auto;
}

iframe {
  width: 100%;
  //height: auto;/*js取得*/

  &#calendar {
    height: $calendar-height;
  }
}

/* ---------- ---------- ---------- ---------- ---------- 県民共済 価格表 */
.price-pc {
  display: none;
  @include media(large) {
    display: block;
  }
}
.price-sp {
  display: block;
  @include media(large) {
    display: none;
  }
}

.item-title{
  padding: 0.5em 0;
  font-size: 1.1em;
  font-weight: bold;
  line-height: 1.5;
}


